.who-I-gift {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
    text-align: center;
    height: calc(100vh - 4rem);

    .container {
        height: fit-content;
        background: #262649;
        border: 1px solid #36365e;
        border-radius: 10px;
        .name-gifted {
            font-size: 5rem;
            font-weight: bolder;
            margin-bottom: 1.5rem;
            padding: 0 1rem;
        }
        .label {
            font-size: 2rem;
            background: #373761;
            border-radius: 0 0 10px 10px;
            padding: 1.5rem 1rem;
        }
    }
    @media screen and (max-width: 580px) {
        .container {
            height: fit-content;
            background: #262649;
            border: 1px solid #36365e;
            border-radius: 10px;
            .name-gifted {
                font-size: 2.5rem;
                font-weight: bolder;
                margin-bottom: .75rem;
            }
            .label {
                font-size: 1rem;
                padding-top: .75rem;
                padding-bottom: .75rem;
                background: #373761;
                border-radius: 0 0 10px 10px;
            }
        }
    }
}
