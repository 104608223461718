$color-soft-blue-opacity: rgba(127, 208, 255, 0.5);
$color-soft-blue: rgb(23, 107, 169);
$color-soft-blue-hover: rgb(107, 188, 255);
$color-blue: rgb(28, 28, 71);
$color-dark-blue: rgb(22, 22, 49);
$color-grey: rgb(70, 70, 86);
$font: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans",
    "Helvetica Neue", sans-serif;

body {
    background-color: $color-dark-blue;
    height: calc(100vh - 4rem);
    margin: 0;
    font-family: $font;
    color: white;
    padding: 2rem 4rem;

    .field {
        margin-bottom: 2rem;
        display: grid;
        width: fit-content;
        label {
            margin-bottom: 0.5rem;
        }
    }

    .block-input {
        border-radius: 0.5rem;
        border: $color-grey solid 1px;
        background-color: $color-blue;
        color: white;
        font-family: $font;
        display: inline-flex;
        input {
            padding: 0.75rem 1rem;
            border: none;
            background: none;
            color: $color-soft-blue;
        }
        input:focus-visible {
            outline: none;
        }
        .icon {
            width: 4rem;
            background: $color-soft-blue;
            color: $color-dark-blue;
            display: flex;
            justify-content: center;
            align-items: center;
            border-top-right-radius: 0.5rem;
            border-bottom-right-radius: 0.5rem;
            border: none;
            cursor: pointer;
        }
        .icon:hover {
            background: $color-soft-blue-hover;
        }
    }
    .block-input:has(:focus-visible) {
        outline: 1px solid $color-soft-blue-hover;
    }

    .table {
        .theader {
            .line .th {
                font-weight: bold;
            }
        }
        .tbody {
            .line .tb {
                font-weight: lighter;
            }
        }
        .line {
            display: inline-flex;
            width: 100%;
            border-top: 1px solid $color-soft-blue-opacity;
            .th,
            .tb {
                padding: 0.75rem;
            }
        }
        .line:hover {
            background-color: $color-soft-blue-opacity;
        }
    }
}

@media screen and (max-width: 580px) {
    body{
        padding: 1rem 0.25rem;
    }
}

.w-1 {
    width: 8.333333333%;
}
.w-2 {
    width: 16.666666667%;
}
.w-3 {
    width: 25%;
}
.w-4 {
    width: 33.333333333%;
}
.w-5 {
    width: 41.666666667%;
}
.w-6 {
    width: 50%;
}
.w-7 {
    width: 58.333333333%;
}
.w-8 {
    width: 64.666666667%;
}
.w-9 {
    width: 75%;
}
.w-10 {
    width: 83.333333333%;
}
.w-11 {
    width: 91.6666666667%;
}
.w-12 {
    width: 100%;
}
