.connection-tmp {
    height: calc(100vh - 4rem);
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
    .form {
        width: 80%;
        .field {
            width: 100%;

            input,
            button {
                padding: 0.5rem 1rem;
                background-color: $color-blue;
                border: none;
                border-radius: 0.5rem;
                color: white;
            }
            input:focus-visible {
                outline: 1px solid rgb(82, 52, 129);
                border-radius: 0.5rem;
            }

            button {
                font-size: large;
            }

            button:hover {
                cursor: pointer;
                background-color: rgb(82, 52, 129);
            }
        }
    }
}
