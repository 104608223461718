.session-search{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    .field {
        text-align: center;
        input{
            width: 20rem;
        }
    }
}